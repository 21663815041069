import React, { useEffect, useState } from 'react';
import { SignUpLayout } from '../../components/Layout';
import { Link, navigate } from 'gatsby';
import api from '../../utils/api';
import handleFormError from '../../utils/handleFormError';
import BlockUi from 'react-block-ui';
import has from 'lodash/has';
import queryString from 'query-string';
import imgSuccess from '../../assets/img/icons/success.svg';
import { OutboundLink, trackCustomEvent } from 'gatsby-plugin-google-analytics';

const Valid = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [confirmStatus, setConfirmStatus] = useState('loading');
  const [appUrl, setAppUrl] = useState(process.env.GATSBY_LOGIN_URL);
  const [platform, setPlatform] = useState(null);
  const [fromMobileApp, setFromMobileApp] = useState(false);

  const validSignUpRequest = async () => {
    const validParams = queryString.parse(location.search);
    const signUpRequestKey = has(validParams, 'k') ? validParams.k : null;
    const propertyId = has(validParams, 'p') ? validParams.p : null;
    const signUpRequestId = has(validParams, 'id') ? validParams.id : null;

    if (signUpRequestId === null || propertyId === null) {
      navigate('/', { state: { from: 'valid-inscription' } });
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await api.post('/users/signup/validation', {
        signUpRequestId,
        propertyId,
        key: signUpRequestKey,
      });

      setConfirmStatus('succeed');
      if (data.token && data.id) {
        setAppUrl(
          `${process.env.GATSBY_WEBAPP_URL}/login/magic-link?token=${data.token}&userId=${data.id}&redirect=/`,
        );

        trackCustomEvent({
          category: 'signUp',
          action: 'clicked on activation email',
          label: propertyId,
        });
      }
    } catch (error) {
      setConfirmStatus('failed');
      handleFormError(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    validSignUpRequest();
  }, []);

  useEffect(() => {
    const validParams = queryString.parse(location.search);

    if (has(validParams, 'platform')) {
      setPlatform(validParams.platform);
    }
  }, [location.search]);

  useEffect(() => {
    setFromMobileApp(['ANDROID', 'IOS'].includes(platform));
  }, [platform]);

  const renderConfirmStatus = () => {
    if (confirmStatus === 'succeed') {
      return (
        <div className="row">
          <div className="col-12 text-align-center pt-6">
            <img src={imgSuccess} alt="Inscription - Validation" />
          </div>

          <div className="col-12 text-align-left pt-5">
            <div className="roboto font-size-lg bold font-size-md-sm text-align-center pl-5 pr-5 pl-2-md pr-2-md">
              Félicitations <span className="font-size-xl">✌️</span>
            </div>
            <div className="text-align-center mt-5 pl-5 pr-5 pl-2-md pr-2-md">
              Votre compte a bien été validé !️
            </div>

            <div className="mt-5 mt-10 pl-4 pr-4">
              <OutboundLink
                href={appUrl}
                className="btn block text-align-center pl-3 pr-3 pt-2 pb-2"
                eventAction="click"
                eventCategory="login"
                eventLabel="first login"
              >
                <div className="font-size-lg-plus pt-1 pb-1">
                  Je me connecte
                </div>
              </OutboundLink>
            </div>
          </div>
        </div>
      );
    }

    if (confirmStatus === 'failed') {
      return (
        <div className="row">
          <div className="col-12 text-align-left pt-5">
            <div className="roboto font-size-lg bold font-size-md-sm text-align-center pl-5 pr-5 pl-0-md pr-0-md text-align-left-md">
              Mince, y'a eu un couac...
            </div>

            <div className="mt-10 pl-4 pr-4">
              <button
                className="btn block text-align-center"
                onClick={validSignUpRequest}
              >
                On réessaye ?
              </button>
            </div>

            <div className="mt-10 pl-4 pr-4">
              Si l'erreur persiste,{' '}
              <Link to={'/contact/'} className="text-underline">
                contactez-nous
              </Link>{' '}
              en précisant votre adresse email et l'adresse renseignée lors de
              votre inscription.
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <SignUpLayout fromMobileApp={fromMobileApp}>
      <BlockUi tag="div" blocking={isLoading} renderChildren={!isLoading}>
        <div className="container step step-ok pl-5-md pr-5-md">
          {renderConfirmStatus()}
        </div>
      </BlockUi>
    </SignUpLayout>
  );
};

export default Valid;
